import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { replaceCompanyName, getCompanyData } from '../constants/config';
import Video from '../components/player/wistia';
import {
  HeroWhatCTStyle,
  Fonts,
  HomeSectionStyle,
  ShadowSec,
  HomeFindTrial,
  HcpTabStyle,
  StatisticSection,
  TabSection,
  CarouselStyle,
  CharterSectionStyle,
  ToggleSection,
  ResearchPipelineSection,
} from '../global.css';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardText,
  Collapse,
  CardBody,
} from 'reactstrap';
import Disclaimer from '../components/footer/disclaimer/disclaimer';
import DisclaimerHcp from '../components/disclaimer-hcp/disclaimer';
import Modal from '../components/modal/modal';
import Gallery from 'components/gallery';
import Img from 'gatsby-image';
import classnames from 'classnames';
import Redirect from '../components/redirect/redirect';
import { registerTrackEvent } from '../helpers/tagManager';

const Hcp = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [showExternalLinkModal, setShowExternalLinkModal] = useState('');
  const [carousel, setCarousel] = useState(false);
  const [collapse, setCollapse] = useState([true, false, false, false]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLoading(false);
    }
  }, []);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMobile = (itemIndex) => {
    setCollapse(
      collapse.map((value, index) => {
        return itemIndex == index ? !value : false;
      })
    );
  };

  const onCarouselNextClick = () => {
    setCarousel(true);
  };

  const onCarouselPrevClick = () => {
    setCarousel(false);
  };

  const handleClick = (event) => {
    if (
      event &&
      event.target &&
      event.target.href &&
      (event.target.href.indexOf('http://') > -1 ||
        event.target.href.indexOf('https://') > -1)
    ) {
      event.preventDefault();
      event.stopPropagation();
      let href = event.target.href;
      if (showExternalLinkModal.length) {
        setShowExternalLinkModal('');
      }
      setTimeout(() => {
        setShowExternalLinkModal(href);
      }, 100);
      setModalOpen(true);
    }
  };

  const renderTabsSection = () => {
    return (
      <HcpTabStyle>
        <Nav tabs>
          <NavItem className={classnames({ activeItem: activeTab === '1' })}>
            <NavLink
              tabIndex={0}
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  toggle('1');
                }
              }}
            >
              {data.hcpEnJson.titleOne}
            </NavLink>
          </NavItem>
          <NavItem className={classnames({ activeItem: activeTab === '2' })}>
            <NavLink
              tabIndex={0}
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  toggle('2');
                }
              }}
            >
              {data.hcpEnJson.titleTwo}
            </NavLink>
          </NavItem>
          <NavItem className={classnames({ activeItem: activeTab === '3' })}>
            <NavLink
              tabIndex={0}
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  toggle('3');
                }
              }}
            >
              {data.hcpEnJson.titleThree}
            </NavLink>
          </NavItem>
          <NavItem className={classnames({ activeItem: activeTab === '4' })}>
            <NavLink
              tabIndex={0}
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  toggle('4');
                }
              }}
            >
              {data.hcpEnJson.titleFour}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className={'tab-content-row'}>
              <Col sm={12} md={4} lg={5} xl={5}>
                <Card className={'img-holder'}>
                  <img
                    className={'summary-icon condition'}
                    src={'/images/clinical-trial-journey.png'}
                    alt={data.hcpEnJson.altTitleOne}
                    onClick={registerTrackEvent({
                      category: 'Image',
                      action: 'Click',
                      label: data.hcpEnJson.altTitleOne,
                    })}
                  />
                </Card>
              </Col>
              <Col sm={12} md={8} lg={7} xl={6}>
                <Card body onClick={(event) => handleClick(event)}>
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: replaceCompanyName(
                        data.hcpEnJson.contentOne.childMarkdownRemark.html
                      ),
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm={12} md={4} lg={5} xl={5}>
                <Card className={'img-holder'}>
                  <img
                    className={''}
                    src={'/images/clinical-trial-awareness.png'}
                    alt={data.hcpEnJson.altTitleTwo}
                    onClick={registerTrackEvent({
                      category: 'Image',
                      action: 'Click',
                      label: data.hcpEnJson.altTitleTwo,
                    })}
                  />
                </Card>
              </Col>
              <Col sm={12} md={8} lg={7} xl={6}>
                <Card body onClick={(event) => handleClick(event)}>
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: replaceCompanyName(
                        data.hcpEnJson.contentTwo.childMarkdownRemark.html
                      ),
                    }}
                  />
                  <figcaption className={'last-element'}>
                    {data.hcpEnJson.markText}
                  </figcaption>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3" className={'video-holder-tab'}>
            <Row>
              <Col
                sm={{ size: 12, offset: 0 }}
                md={{ size: 8, offset: 0 }}
                lg={{ size: 7, offset: 0 }}
                xl={{ size: 6, offset: 1 }}
              >
                <Card body onClick={(event) => handleClick(event)}>
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: replaceCompanyName(
                        data.hcpEnJson.contentThree.childMarkdownRemark.html
                      ),
                    }}
                  />
                </Card>
              </Col>
              <Col sm={12} md={4} lg={5} xl={5}>
                <Card className={'video-holder'}>
                  <Video code={'rzw677wcuz'} title={'Moshe Talpaz'} />
                  <figcaption className={'footnote'}>
                    {data.hcpEnJson.videoPermissionTab}
                  </figcaption>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4" className={'video-holder-tab'}>
            <Row>
              <Col
                sm={{ size: 12, offset: 0 }}
                md={{ size: 8, offset: 0 }}
                lg={{ size: 7, offset: 0 }}
                xl={{ size: 6, offset: 1 }}
              >
                <Card body onClick={(event) => handleClick(event)}>
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: replaceCompanyName(
                        data.hcpEnJson.contentFour.childMarkdownRemark.html
                      ),
                    }}
                  />
                </Card>
              </Col>
              <Col sm={12} md={4} lg={5} xl={5}>
                <Card className={'video-holder'}>
                  <Video
                    code={data.hcpEnJson.videoFour}
                    title={data.hcpEnJson.videoTitleFour}
                  />
                  <figcaption className={'footnote'}>
                    {data.hcpEnJson.videoTitleFour}
                  </figcaption>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        {showExternalLinkModal && showExternalLinkModal.length ? (
          <Modal
            autoOpen={true}
            open={modalOpen}
            showModal={showModal}
            hideModal={hideModal}
            type={'redirect'}
            trackEvent={{
              label: 'Redirect Popup',
            }}
          >
            <Redirect hideModal={hideModal} url={showExternalLinkModal} />
          </Modal>
        ) : undefined}
      </HcpTabStyle>
    );
  };

  const renderCollapseMobile = () => {
    return (
      <div>
        <button
          className={'first-btn' + (collapse[0] === true ? ' borderPink' : '')}
          onClick={() => toggleMobile(0)}
        >
          {data.hcpEnJson.titleOne}
        </button>
        <Collapse isOpen={collapse[0]}>
          <Card onClick={(event) => handleClick(event)}>
            <CardBody>
              <img
                className={'summary-icon condition'}
                src={'/images/clinical-trial-journey.png'}
                alt={data.hcpEnJson.altTitleOne}
                onClick={registerTrackEvent({
                  category: 'Image',
                  action: 'Click',
                  label: data.hcpEnJson.altTitleOne,
                })}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceCompanyName(
                    data.hcpEnJson.contentOne.childMarkdownRemark.html
                  ),
                }}
              />
            </CardBody>
          </Card>
        </Collapse>

        <button
          className={'middle-btn' + (collapse[1] === true ? ' borderPink' : '')}
          onClick={() => toggleMobile(1)}
        >
          {data.hcpEnJson.titleTwo}
        </button>
        <Collapse isOpen={collapse[1]}>
          <Card
            className={'middleCard'}
            onClick={(event) => handleClick(event)}
          >
            <CardBody>
              <img
                className={''}
                src={'/images/clinical-trial-awareness.png'}
                alt={data.hcpEnJson.altTitleTwo}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceCompanyName(
                    data.hcpEnJson.contentTwo.childMarkdownRemark.html
                  ),
                }}
              />
              <figcaption className={'last-element middleFig'}>
                {data.hcpEnJson.markText}
              </figcaption>
            </CardBody>
          </Card>
        </Collapse>

        <button
          className={'last-btn' + (collapse[2] === true ? ' borderPink' : '')}
          onClick={() => toggleMobile(2)}
        >
          {data.hcpEnJson.titleThree}
        </button>
        <Collapse isOpen={collapse[2]}>
          <Card className={'lastCard'} onClick={(event) => handleClick(event)}>
            <CardBody>
              <div
                style={{ paddingBottom: '20px' }}
                dangerouslySetInnerHTML={{
                  __html: replaceCompanyName(
                    data.hcpEnJson.contentThree.childMarkdownRemark.html
                  ),
                }}
              />
              <Video code={'rzw677wcuz'} title={'Moshe Talpaz'} />
              <figcaption className={'footnote video'}>
                {data.hcpEnJson.videoPermissionTab}
              </figcaption>
            </CardBody>
          </Card>
        </Collapse>

        <button
          className={'last-btn' + (collapse[3] === true ? ' borderPink' : '')}
          onClick={() => toggleMobile(3)}
        >
          {data.hcpEnJson.titleFour}
        </button>
        <Collapse isOpen={collapse[3]}>
          <Card className={'lastCard'} onClick={(event) => handleClick(event)}>
            <CardBody>
              <div
                style={{ paddingBottom: '20px' }}
                dangerouslySetInnerHTML={{
                  __html: replaceCompanyName(
                    data.hcpEnJson.contentFour.childMarkdownRemark.html
                  ),
                }}
              />
              <Video
                code={data.hcpEnJson.videoFour}
                title={data.hcpEnJson.videoTitleFour}
              />
              <figcaption className={'footnote video'}>
                {data.hcpEnJson.videoTitleFour}
              </figcaption>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  };

  return isLoading ? (
    ''
  ) : (
    <Layout meta={replaceCompanyName(data.hcpEnJson, 'seo').seo}>
      <Fonts>
        <HeroWhatCTStyle className={'hcp-Hero'}>
          <Row>
            <Col xl={4} lg={12} md={12} sm={12} className={'heroText'}>
              <h1>{data.hcpEnJson.heroTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceCompanyName(
                    data.hcpEnJson.heroContent.childMarkdownRemark.html
                  ),
                }}
              />
            </Col>
            <Col xl={8} lg={10} md={12} sm={12} className={'hero-video'}>
              <Video
                code={'gihu4iqnbq'}
                title={'Only Through ClinicalTrials Video'}
              />
              <p className={'permission'}>{data.hcpEnJson.videoPermission}</p>
            </Col>
          </Row>
        </HeroWhatCTStyle>
        <HomeFindTrial>
          <Row>
            <Col xs={12}>
              <h2 className={'pink'}>{data.hcpEnJson.HPTitle}</h2>
            </Col>
            {data.hcpEnJson.HPcontent.map((item, index) => (
              <Col md={3} sm={6} xs={12} key={index} className={'hcp-section'}>
                <Img
                  fluid={item.image.childImageSharp.fluid}
                  alt={item.altText}
                  durationFadeIn={0}
                  loading="eager"
                />
                <p>{item.text}</p>
              </Col>
            ))}
          </Row>
        </HomeFindTrial>
        <CharterSectionStyle>
          <Row>
            <Col md={12} className={'text-col'}>
              <h2>
                {data.hcpEnJson.targetSection.title}
                <br></br>
                {data.hcpEnJson.targetSection.subTitle}
              </h2>
              <figcaption className={'footnote'}>
                <strong>{data.hcpEnJson.physiciansSection.source}</strong>
                {data.hcpEnJson.targetSection.footnote}
              </figcaption>
            </Col>
            <Col md={12} className={'img-holder'}>
              <Img
                className={'desktop-img'}
                fluid={data.hcpEnJson.targetSection.image.childImageSharp.fluid}
                alt={data.hcpEnJson.targetSection.altText}
                durationFadeIn={0}
                loading="eager"
              />
              <Img
                className={'mobile-img'}
                fluid={
                  data.hcpEnJson.targetSection.imageMobile.childImageSharp.fluid
                }
                alt={data.hcpEnJson.targetSection.altText}
                durationFadeIn={0}
                loading="eager"
              />
            </Col>
          </Row>
        </CharterSectionStyle>
        <CarouselStyle>
          <Row>
            {carousel === false ? (
              <Col md={12} className={'mini-carousel'}>
                <button onClick={onCarouselPrevClick} className={'prev'}>
                  <img
                    className={'prev-btn'}
                    src={'/images/prev.png'}
                    alt={data.hcpEnJson.targetSection.altText}
                  />
                </button>
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceCompanyName(
                      data.hcpEnJson.targetSection.carouselText
                        .childMarkdownRemark.html
                    ),
                  }}
                />
                <button onClick={onCarouselNextClick} className={'next'}>
                  <img
                    className={'prev-btn'}
                    src={'/images/next.png'}
                    alt={data.hcpEnJson.targetSection.altText}
                  />
                </button>
              </Col>
            ) : (
              <Col md={12} className={'mini-carousel'}>
                <button onClick={onCarouselPrevClick} className={'prev'}>
                  <img
                    className={'prev-btn'}
                    src={'/images/prev.png'}
                    alt={data.hcpEnJson.targetSection.altText}
                  />
                </button>
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceCompanyName(
                      data.hcpEnJson.targetSection.carouselTextTwo
                        .childMarkdownRemark.html
                    ),
                  }}
                />
                <button onClick={onCarouselNextClick} className={'next'}>
                  <img
                    className={'prev-btn'}
                    src={'/images/next.png'}
                    alt={data.hcpEnJson.targetSection.altText}
                  />
                </button>
              </Col>
            )}
          </Row>
        </CarouselStyle>
        <StatisticSection>
          <Row>
            <Col md={12} className={'title'}>
              <h2>{data.hcpEnJson.physiciansSection.title}</h2>
              <figcaption className={'footnote'}>
                <strong>{data.hcpEnJson.physiciansSection.source}</strong>
                {data.hcpEnJson.physiciansSection.footnote}
              </figcaption>
            </Col>
            <Col md={6} className={'content-holder'} align="center">
              <Img
                fluid={
                  data.hcpEnJson.physiciansSection.imageOne.childImageSharp
                    .fluid
                }
                alt={data.hcpEnJson.physiciansSection.altText}
                durationFadeIn={0}
                loading="eager"
              />
              <p>
                <strong>{data.hcpEnJson.physiciansSection.strong84}</strong>
                {data.hcpEnJson.physiciansSection.textOne}
              </p>
            </Col>
            <Col md={6} className={'content-holder'} align="center">
              <Img
                fluid={
                  data.hcpEnJson.physiciansSection.imageTwo.childImageSharp
                    .fluid
                }
                alt={data.hcpEnJson.physiciansSection.altText}
                durationFadeIn={0}
                loading="eager"
              />
              <p>
                <strong>{data.hcpEnJson.physiciansSection.strong71}</strong>
                {data.hcpEnJson.physiciansSection.textTwo}
              </p>
            </Col>
            <Col md={12} className={'difference-text'}>
              {data.hcpEnJson.physiciansSection.difference}
            </Col>
          </Row>
        </StatisticSection>
        <TabSection>{renderTabsSection()}</TabSection>
        <ToggleSection>{renderCollapseMobile()}</ToggleSection>
        <ResearchPipelineSection>
          <div className={'container'}>
            <span>{'Learn more about'}</span>
            <a
              href={getCompanyData(data.hcpEnJson, 'researchPipeline')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'research pipeline'}
            </a>
          </div>
        </ResearchPipelineSection>
        <HomeSectionStyle>
          <ShadowSec className={'transparency-section'} />
          <Row className={'transparency-section'}>
            <Col xs={12}>
              <h2>
                {getCompanyData(data.homeJson, 'clinicalTransparencyTitle')}
              </h2>
            </Col>
            <Col className={'item'} xs={12}>
              <Gallery
                items={data.homeJson.transparencyContent.map((item) => {
                  return {
                    ...item,
                    url: getCompanyData(item, 'url'),
                  };
                })}
                showMore={data.homeJson.showMore}
                showLess={data.homeJson.showLess}
              />
            </Col>
          </Row>
        </HomeSectionStyle>
        <HomeSectionStyle>
          <ShadowSec className={'disclaimer'} />
          <Row className={'disclaimer'}>
            <Col>
              <Modal
                button={'DISCLAIMER'}
                hideModal={hideModal}
                showModal={showModal}
                open={modalOpen}
                type={'redirect'}
                trackEvent={{
                  label: 'Disclaimer',
                }}
              >
                <Disclaimer hideModal={hideModal} />
              </Modal>
            </Col>
          </Row>
        </HomeSectionStyle>
        <Modal
          hideModal={hideModal}
          showModal={showModal}
          open={modalOpen}
          autoOpen={true}
          type={'redirect'}
          trackEvent={{
            label: 'Healthcare Disclaimer',
          }}
        >
          <DisclaimerHcp hideModal={hideModal} />
        </Modal>
      </Fonts>
    </Layout>
  );
};

Hcp.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hcp;

export const query = graphql`
  query Hcp {
    hcpEnJson {
      seo {
        pageTitle
        pageDescription
      }
      targetSection {
        carouselText {
          childMarkdownRemark {
            html
          }
        }
        carouselTextTwo {
          childMarkdownRemark {
            html
          }
        }
        altText
        footnote
        title
        subTitle
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageMobile {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      physiciansSection {
        footnote
        source
        title
        textOne
        strong84
        strong71
        textTwo
        difference
        altText
        imageOne {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageTwo {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      heroTitle
      videoPermission
      videoPermissionTab
      heroContent {
        childMarkdownRemark {
          html
        }
      }
      HPTitle
      HPcontent {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        altText
      }
      titleOne
      titleTwo
      titleThree
      titleFour
      videoFour
      videoTitleFour
      altTitleOne
      altTitleTwo
      markText
      contentOne {
        childMarkdownRemark {
          html
        }
      }
      contentTwo {
        childMarkdownRemark {
          html
        }
      }
      contentThree {
        childMarkdownRemark {
          html
        }
      }
      contentFour {
        childMarkdownRemark {
          html
        }
      }
      researchPipeline
      researchPipeline_merck
    }
    homeJson {
      clinicalTransparencyTitle
      clinicalTransparencyTitle_merck
      showMore
      showLess
      transparencyContent {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        copy
        url
        url_merck
        altTitle
      }
    }
  }
`;
