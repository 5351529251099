/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { DisclaimerContent } from './disclaimer.css';
import { getCompanyData } from '../../constants/config';

const Disclaimer = ({ data, hideModal }) => {

  const handleClick = event => {
    if (
      event &&
      event.target &&
      event.target.href &&
      event.target.href.indexOf('continue') > -1
    ) {
      event.preventDefault();
      event.stopPropagation();
      hideModal();
    }
  };

  return (
    <DisclaimerContent>
      <div
        onClick={event => handleClick(event)}
        dangerouslySetInnerHTML={{
          __html: getCompanyData(data.disclaimerJson, 'content')
            .childMarkdownRemark.html,
        }}
      />
    </DisclaimerContent>
  );
};

Disclaimer.propTypes = {
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func,
};

const DisclaimerQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        disclaimerJson {
          content {
            childMarkdownRemark {
              html
            }
          }
          content_merck {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Disclaimer data={data} {...props} />}
  />
);

export default DisclaimerQuery;
