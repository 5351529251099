import styled from 'styled-components';

export const DisclaimerContent = styled.div`
  padding: 0 40px;
  margin-bottom: -20px;
  h1 {
    color: #503291;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-weight: 900;
    margin-top: 40px;
  }
  h2 {
    color: #503291;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  h3 {
    margin: 40px 0 60px 0;
    height: 38px;
  }
  h4 {
    color: #000000;
    font-size: 18px;
    line-height: 32px;
    margin: 10px 0;
    text-align: center;
  }
  p {
    color: #1b1b25;
    font-size: 16px;
    line-height: 27px;
    margin-top: 6px;
  }
  a {
    background-color: #eb3c96;
    height: 38px;
    width: 101px;
    padding: 12px 14px;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    display: inline-block;
    :hover {
      text-decoration: none;
      background-color: #A52A69;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 10px;
    h1 {
      font-size: 22px;
      line-height: 32px;
      text-align: left;
      margin-top: 25px;
    }
    h2 {
      font-size: 18px;
      line-height: 26px;
    }
    h4 {
      text-align: left;
      font-size: 16px;
      line-height: 27px;
    }
  }
`;
